
import { FcUserEntityModel, FcUserQueryModel } from '@common-src/entity-model/fc-user-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';
import { BaseModel } from '@common-src/model/base-model';

export class RoomTreeModel extends BaseModel {
    title: string = undefined;
    value: string = undefined;
    key: string = undefined;
    id: string = undefined;
    pId: string = undefined;
    children: Array<any> = undefined;
    scopedSlots: any = { title: 'custom' };
    parent: any = undefined;
    selectable: boolean = true;
    visible: boolean = true;
    // disabled: boolean = true;
    sort: number = null;

    toModel(json): any {
        this.title = _.get(json, 'name') || _.get(json, 'locationName');
        this.key = this.value = this.id = _.get(json, 'id') || _.get(json, 'locationId');
        this.pId = _.get(json, 'parentId');
        this.sort = _.get(json, 'sort');
        if (!_.isUndefined(_.get(json, 'selectable'))) {
            this.selectable = !!_.get(json, 'selectable');
            // this.disabled = !this.selectable;
        }
        if (!_.isUndefined(_.get(json, 'visible'))) {
            this.visible = _.get(json, 'visible') === 'visible';
        }
        if (json) {
            this.children = _.map(json.children || json.roomList, item => {
                item.parentId = this.id;
                const child: RoomTreeModel = new RoomTreeModel().toModel(item);
                child.parent = this;
                return child;
            });
        } else {
            this.children = [];
        }
        // return this;
        return _.toPlainObject(this);
    }
}

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/personnelManagement`;

class FcUserService implements ICRUDQ<FcUserEntityModel, FcUserQueryModel> {
    async create(model: FcUserEntityModel):Promise<FcUserEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FcUserEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new FcUserEntityModel().toModel(res);
    }

    async update(model: FcUserEntityModel):Promise<FcUserEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FcUserEntityModel):Promise<FcUserEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FcUserQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FcUserEntityModel().toModel(item));
        return res;
    }
}

export default new FcUserService();

export async function feeSettingQuery():Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/financialManagement/cost/setting/query`;
    const res = await get(url);
    return res;
}

export async function feeSettingSave(params: any):Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/financialManagement/cost/setting/save`;
    const res = await post(url, params);
    return res;
}

export async function getRoomTree():Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/room/tree`;
    const res = await get(url);
    return _.map(res, item => {
        item.selectable = false;
        const treeModel = new RoomTreeModel().toModel(item);
        if (treeModel && treeModel.children) {
            _.forEach(treeModel.children, subItem => {
                subItem.selectable = false;
            });
        }
        return treeModel;
    });
}

export async function getLocation():Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/room/tree`;
    const res = await get(url);
    return _.map(res, item => {
        return {
            name: item.locationName,
            value: item.locationId
        };
    });
}

export async function batchUpdateAcidRecord(params: any):Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/personnelManagement/nucleicAcidTestRecord/batchSave`;
    const res = await post(url, params);
    return res;
}

export async function getRecordList(id: string):Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/personnelManagement/nucleicAcidTestRecord/${id}`;
    const res = await get(url);
    return res;
}

export async function saveRecordList(params: any):Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/personnelManagement/nucleicAcidTestRecord/save`;
    const res = await post(url, params);
    return res;
}

export async function batchUpdateUser(params: any):Promise<any> {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/personnelManagement/batchSave`;
    const res = await post(url, params);
    return res;
}
