import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { MOBILE_REGEXP, NUMBER_INT_REGEXP } from '@common-src/model/regexp';
import moment, { Moment } from 'moment';
import { dateFormat } from '@common-src/filter';
import { getRoomTree, getLocation } from '@common-src/service/fc-user';

export const sourceList = [
    { name: '历城区', value: '历城区' },
    { name: '槐荫区', value: '槐荫区' },
    { name: '高新区', value: '高新区' },
    { name: '济阳区', value: '济阳区' },
    { name: '商河县', value: '商河县' },
    { name: '平阴县', value: '平阴县' },
    { name: '历下区', value: '历下区' },
    { name: '天桥区', value: '天桥区' },
    { name: '起步区', value: '起步区' },
    { name: '南部山区', value: '南部山区' },
    { name: '长清区', value: '长清区' },
    { name: '境外', value: '境外' },
    { name: '市指挥部', value: '市指挥部' }
];

export class FcUserEntityModel extends BaseEntityModel {
    @FormControl({
        label: '手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '房间号',
        type: FormControlType.TREE_SELECT,
        optionsPromise: getRoomTree,
        required: true
    } as FormControlTextModel)
    roomId: string = undefined;
    roomName: string = undefined;
    roomCount: number = undefined;
    locationName: string = undefined;

    @FormControl({
        label: '来源',
        type: FormControlType.SELECT,
        options: sourceList,
        required: true
    } as FormControlTextModel)
    source: string = undefined;

    @FormControl({
        label: '年龄',
        type: FormControlType.NUMBER,
        pattern: NUMBER_INT_REGEXP,
        min: 0
    } as FormControlTextModel)
    age: string = undefined;

    @FormControl({
        label: '性别',
        type: FormControlType.RADIO_GROUP,
        options: [
            { value: 'MAN', name: '男' },
            { value: 'WOMAN', name: '女' }
        ]
    } as FormControlOptionModel)
    sex: number = undefined;

    @FormControl({
        label: '密接类型',
        type: FormControlType.SELECT,
        options: [
            { value: 'CLOSE_CONTACT', name: '密接' },
            { value: 'SECONDARY_CONTACT', name: '次密接' },
            { value: 'KEY_PERSONNEL', name: '重点人员' },
            { value: 'OTHER', name: '其他' }
        ]
    } as FormControlOptionModel)
    quarantineType: number = undefined;

    @FormControl({
        label: '开始隔离日期',
        type: FormControlType.SELECT_DATE,
        showTime: true,
        required: true
    } as FormControlTextModel)
    quarantineStart: Moment = undefined;

    @FormControl({
        label: '应隔离日期',
        type: FormControlType.SELECT_DATE,
        showTime: true
    } as FormControlTextModel)
    quarantineEnd: Moment = undefined;

    @FormControl({
        label: '实际离舱日期',
        type: FormControlType.SELECT_DATE,
        showTime: true,
        message: '实际离舱日期影响财务计算，请慎重输入',
        noMessageTooltip: true
    } as FormControlTextModel)
    actualQuarantineEnd: Moment = undefined;

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '抽血次数',
        type: FormControlType.NUMBER,
        pattern: NUMBER_INT_REGEXP,
        min: 0
    } as FormControlTextModel)
    drawBloodNum: string = undefined;

    // @FormControl({
    //     label: '核酸记录',
    //     type: FormControlType.ARRAY_STRING
    // } as FormControlModel<string>)
    // recordList: Array<string> = [''];

    static getTableColumns() {
        return [
            {
                title: '手机号',
                dataIndex: 'phone'
            },
            {
                title: '入住位置',
                dataIndex: 'locationName'
            },
            {
                title: '房间号',
                dataIndex: 'roomName'
            },
            {
                title: '来源',
                dataIndex: 'source'
            },
            {
                title: '开始隔离日期',
                dataIndex: 'quarantineStart',
                customRender: (text, record) => {
                    return dateFormat(record.quarantineStart);
                }
            },
            {
                title: '实际离舱日期',
                dataIndex: 'actualQuarantineEnd',
                customRender: (text, record) => {
                    return dateFormat(record.actualQuarantineEnd);
                }
            },
            {
                title: '应隔离日期',
                dataIndex: 'quarantineEnd',
                customRender: (text, record) => {
                    return dateFormat(record.quarantineEnd);
                }
            },
            {
                title: '入住人数',
                dataIndex: 'roomCount'
            },
            {
                title: '备注',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (json.quarantineStart) {
            this.quarantineStart = moment(json.quarantineStart);
        }
        if (json.quarantineEnd) {
            this.quarantineEnd = moment(json.quarantineEnd);
        }
        if (json.actualQuarantineEnd) {
            this.actualQuarantineEnd = moment(json.actualQuarantineEnd);
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.quarantineStart = this.quarantineStart?.toDate()?.getTime();
        data.quarantineEnd = this.quarantineEnd?.toDate()?.getTime();
        data.actualQuarantineEnd = this.actualQuarantineEnd?.toDate()?.getTime();
        return data;
    }
}

export class FcUserQueryModel extends QueryPageModel {
    @QueryControl({
        label: '手机号',
        type: QueryControlType.TEXT
    })
    phone: string = undefined;

    @QueryControl({
        label: '楼栋',
        type: QueryControlType.SELECT,
        optionsPromise: getLocation,
        hasAllOption: true
    })
    locationId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '房间号',
        type: QueryControlType.TEXT
    })
    roomName: string = undefined;

    @QueryControl({
        label: '来源',
        type: QueryControlType.SELECT,
        hasAllOption: true,
        options: sourceList
    })
    source: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '开始隔离日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_30_DAY();

    toService() {
        const data: any = super.toService();
        data.startTime = this.dateRange[0].startOf('day').toDate().getTime();
        data.endTime = this.getEndTime(this.dateRange[1]);
        delete data.dateRange;
        return {
            params: data
        };
    }
}
